import * as React from "react"
import {PriceCard} from "../components/PriceCard";
import {Layout} from "../components/Layout";

export default function OnderhoudsbeurtenPage() {
    return (
        <Layout contactWidget={true}
                pageTitle={"Bekijk onze MTB onderhoudsbeurten!✓ | Steven's bike service"}
                metaDescription={"Jouw mountainbike voor onderhoud? Bekijk dan nu de onderhoudsbeurten van Steven's bike service! Dé MTB specialist van regio Deventer!✓"}>
            <h1 className={"text-3xl sm:text-4xl md:text-5xl font-bold mt-24 mb-10"}>De MTB onderhoudsbeurten</h1>
            <h2 className={"text-xl sm:text-2xl md:text-3xl font-bold mb-10"}>Onderzoek & klein onderhoud</h2>

            <div className="w-full grid gap-10 md:grid-cols-2 grid-flow-row">
                <PriceCard
                    type={"Start licentie"}
                    price={"35"}
                    checks={[
                        (<span>Controle op 42 punten van het frame en onderdelen op werking en veiligheid.</span>),
                        (<span>Rapport van de staat van de fiets.</span>),
                    ]}/>
                <PriceCard
                    type={"Junior"}
                    price={"75"}
                    basedOn={"Start licentie"}
                    checks={[
                        (<span>Afstellen van het remsysteem, het versnellingssysteem, de naven, de trapas en het balhoofd.</span>),
                        (<span>Banden en eventueel voorvork en demper op druk.</span>),
                    ]}/>
            </div>
            <h2 className={"text-xl sm:text-2xl md:text-3xl font-bold mt-10"}>Periodiek onderhoud</h2>
            <div className="w-full my-10 grid gap-10 md:grid-cols-3">
                <PriceCard
                    type={"Belofte"}
                    price={"150*"}
                    basedOn={"Junior"}
                    checks={[
                        (<span>Naast het afstellen ook het reinigen en smeren van de naven, de trapas en het balhoofd.</span>),
                    ]}/>
                <PriceCard
                    populair
                    type={"Elite"}
                    price={"250*"}
                    basedOn={"Belofte"}
                    fullSuspensionPrice={"300*"}
                    checks={[
                        (<span>Alles wordt compleet gedemonteerd.</span>),
                        (<span>Alle onderdelen en het frame worden gereinigd en gepoetst.</span>),
                    ]}/>
                <PriceCard
                    type={"Prof"}
                    price={"300*"}
                    basedOn={"Elite"}
                    fullSuspensionPrice={"350*"}
                    checks={[(<span>Het frame en alle onderdelen worden behandeld met <a
                        className={"text-blue-600 font-bold whitespace-nowrap hover:underline"} target="_blank"
                        href={"https://eu.muc-off.com/products/harsh-condition-barrier-1-400ml"}>MUC-OFF HCB-1</a>.</span>)]}/>
            </div>
            <div>
                * Bij kogel conus naaf € 30,- meerwerk.
            </div>
        </Layout>
    )
}
